/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/home/Vision.js
Created On : 26 April 2022 16:40:40
*/

import React from "react"

const Vision = () => {
  return (
    <div className="h--vision">
      <div className="v--wrapper">
        <div className="img-container">
          <img src="/vision.jpg" alt="Vision" />
        </div>
        <div className="caption-container">
          <h1>Notre vision</h1>
          <p>
            « Faire de toutes les familles des foyers d’adorateurs pour Dieu par
            l’instauration du culte familial au moyen de la foi en Christ Jésus
            par la puissance du Saint Esprit et par l’enseignement des principes
            divins. »
          </p>
          <p>
            Cette vision a une dimension apostolique et prophétique qui la
            caractérise dans son exercice auprès des familles. Elle est une main
            d’accompagnement pour l’édification du corps du Christ à travers le
            monde auprès des églises locales et des autres ministères.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Vision
