/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/home/UpCommingEvents.js
Created On : 14 June 2022 09:32:42
*/
import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import { ProgramDisplayer } from "../../pages/Program"
import { PROGRAM } from "../../routes"
import { HOME_FETCH } from "../../routes/api"
import { useMakeRequest } from "../../services/httpClient"

const UpCommingEvents = ({ event = null }) => {
  return (
    <div>
      {event && (
        <div className="program-page  bg-whitesmoke">
          <div className="p--wrapper">
            <h1 className="title"> Événement à venir</h1>
            <div className="items">
              <Link to={PROGRAM}>
                <img
                  alt="Programme à venir"
                  src={event.image}
                  className="w-100 my-3"
                />
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default UpCommingEvents
