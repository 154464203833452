/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/App.js
Created On : 18 April 2022 10:43:58
*/

import React from "react"
import Layout from "./layouts/master"
import Router from "./routes/router"
import { Toaster } from "react-hot-toast"
const App = () => {
  return (
    <Layout>
      <Router />
      <Toaster />
    </Layout>
  )
}

export default App
