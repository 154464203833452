/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/About.js
Created On : 26 April 2022 14:20:10
*/

import React from "react"

const About = () => {
  return (
    <div className="about-p">
      <div className="s--title">
        <h1>Le Ministère</h1>
        <div className="overlay"></div>
      </div>
      <div className="wrapper">
        <section>
          <p>
            <img src="ministry-float.jpg" alt="Ministry" className="floatter" />
            Lors d’une grande réunion des serviteurs de Dieu dans la ville de
            Kinshasa en Octobre 1998, un homme de Dieu venu de l’étranger avait
            prié pour nous. Et sous l’onction et l’impact de la grâce, je me
            suis retrouvée au sol et de là mes yeux se sont ouverts.
          </p>
          <p>
            Je vis une vision claire, sur une estrade il y avait différents
            instruments et personnes selon chaque culture et différentes races,
            couleurs de peau, tribus et nationalités en train de jouer une
            musique et d’adorer Dieu en différentes langues. Et quelqu’un a
            commencé à crier vers moi le passage du livre d’apocalypse <b>5</b>
            :<br />
            <span>
              <em>9.</em> Et ils chantaient un cantique nouveau : Tu es seul
              digne de recevoir le livre, et d’en briser les sceaux car tu as
              été égorgé et tu as racheté pour Dieu, grâce à ton sacrifice, des
              hommes de toute tribu, de toute langue, de tout peuple, de toutes
              les nations.
              <br /> <em>10.</em> Tu as fait d’eux un peuple de rois et de
              prêtres pour notre Dieu, et ils régneront sur la terre.
            </span>
          </p>
          <p>
            Et j’entendis une voix me demander d’écrire ce que je voyais et me
            dira aussi : <br />« Rassemble pour moi des familles, enseigne les
            familles à devenir des foyers d’adorateurs pour Dieu et prépare les
            pour le retour de Christ, car je veux voir des familles monter au
            ciel pour adorer encore. » <br />
            J’ai partagé cette vision à mon encadreur et responsable de
            département avec qui nous avions prié et je suis resté encore dans
            l’église durant quelques années.
          </p>
          <ul>
            <li>
              <div className="date">En 2002</div>
              <p>
                Nous avons commencé à opérer par la grâce de Dieu de la manière
                suivante, j’ai commencé à organiser des weekends de prières en
                famille. Chaque samedi soir dans une famille, nous allions pour
                prier avec une famille, partager la parole de Dieu et partager
                des petits repas en communion fraternelle.
              </p>
            </li>
            <li>
              <div className="date">En 2006</div>
              <p>
                Nous avons commencé une autre dimension de nos rencontres. Cette
                fois-là on organisera des soirées d’adoration dans une résidence
                familiale avec quelques chantres du Seigneur dans le salon et en
                clôturant aussi ce moment-là avec un repas en famille.
              </p>
            </li>
            <li>
              <div className="date">En 2010</div>
              <p>
                Nous allons cette fois-ci travailler pleinement dans
                l’effectivité du ministère que Dieu nous a appelé à accomplir
                jusqu’à ce jour. Le ministère s’agrandi en activités et la
                structure est en train de se construire jour après jour par la
                grâce de Dieu.
              </p>
            </li>
          </ul>
        </section>
      </div>
    </div>
  )
}

export default About
