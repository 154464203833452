/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/services/Notification.js
Created On : 12 May 2022 17:06:16
*/

import toast from "react-hot-toast"

export const notify = ({
  type,
  message,
  duration = 5000,
  position = "top-center",
  style = {},
  className = "",
  iconTheme = {},
  ariaProps = {},
}) => {
  toast[type ? type : "success"](message, {
    duration: duration,
    position: position,
    style: style,
    className: className,
    iconTheme: iconTheme,
    ariaProps: ariaProps,
  })
}
