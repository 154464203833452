/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/Branches.js
Created On : 28 April 2022 19:43:15
*/

import React from "react"

const Branches = () => {
  const ItemDisplayer = ({ item }) => {
    return (
      <div className="section">
        <h1>{item.title}</h1>
        <div>
          <p>{item.description}</p>
        </div>
      </div>
    )
  }

  return (
    <div className="b--page">
      <div className="pb--wrapper">
        <h1 className="m-title">Nos branches</h1>
        {items.map((item, key) => (
          <ItemDisplayer item={item} key={key} />
        ))}
      </div>
    </div>
  )
}

export default Branches

const items = [
  {
    title: "Soirée adoration en famille",
    description:
      "La branche qui organise les soirées d’adoration en famille. Elle rassemble tous les 3 mois dans une salle les familles qui viennent pour un moment d’adoration et d’enseignement sur la famille. ",
  },
  {
    title: "Atelier Mahombi Pour les familles",
    description:
      "Une journée de prière et d’intercession pour les familles. Mahombi qui veut dire prière, c’est un atelier pour forger les familles à la prière à travers l’enseignement et la pratique des principes spirituels. Cette réunion a lieu tous les Mercredis soir à 17 heures / Heure de Kinshasa.",
  },
  {
    title: "J’aime ma Famille",
    description:
      "Un programme de diffusion des séries d'enseignements sur la famille sur YouTube et les chaînes de Télé. C’est à la fois une confession de vie et une identité ou une culture que nous voulons développer comme principe de base de la vie et rétablir la place de la famille sans la vie des personnes qui nous suivent. A travers ses diffusions et ses interviews, nous ciblons tous les âges et souhaitons présenter autrement le message que Dieu nous a mis à cœur. ",
  },
  {
    title: "Actions Dorcas Davida",
    description:
      "La branche compassion du ministère où sa vision pour les familles se réalise à travers l’assistance aux démunis, aux malades, les visites dans les maternités, etc. Action Dorcas est une ONG créée pour accomplir cette mission à travers plusieurs actions et activités organisées au cours de l’année",
  },
  {
    title: "Sans Tabou",
    description:
      "« Réunion d’une Mère avec ses filles » où la mère, parle sans tabou et enseigne en toute liberté à ses filles sur les questions de la vie, du couple et sur différents sujets. Cette réunion se tient en présentiel, en live et en émission diffusée sur la chaîne YouTube et sur les chaînes de télé",
  },
  {
    title: "Brunch Women (Déjeuner des femmes) ",
    description:
      "Une matinée évangélique pour le salut des femmes et d’échanges d’expériences avec les femmes de toutes catégories sociales autour d’un déjeuner. Cette journée a lieu une fois l’année",
  },
  {
    title: "A Nous Deux pour La Vie",
    description:
      "Une branche spécialement axée sur les couples. Le concept de la vie à deux est au cœur de l’action de Dieu pour les hommes. A Nous deux pour la vie avec toutes ses réalités, ses défis, ses avantages et son impact. Une réunion des couples où il y a aussi des surprises et des moments inoubliables.",
  },
  {
    title: "Hommes Excellents ",
    description:
      "L’excellence est une valeur. Les valeurs d’excellence se transmettent d’une personne à une autre. Nous avons grandi dans une culture où c’est toujours la femme qu’il faut préparer, éduquer et encadrer mais, nous avions oublié les hommes.Ce qui est en partie la cause de beaucoup de déséquilibres dans les foyers et la société.Les hommes n’ont jamais été préparés pour la vie de foyer.Cette réunion vise la transmission, le partage des valeurs d’excellence entre hommes de tout âge adulte à partir de 17 ans.",
  },
]
