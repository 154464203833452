/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/Home.js
Created On : 26 April 2022 15:36:24
*/

import React, { useState, useEffect } from "react"
import Branches from "../components/home/Branches"
import Hiro from "../components/home/Hiro"
import JackAndElizabeth from "../components/home/JackAndElizabeth"
import Partnership from "../components/home/Partnership"
import UpCommingEvents from "../components/home/UpCommingEvents"
import Vision from "../components/home/Vision"
import NewsLetter from "../components/NewsLetter"
import { HOME_FETCH } from "../routes/api"
import { useMakeRequest } from "../services/httpClient"
import { notifyErrors } from "../services/Validation"

const Home = () => {
  const [upComingEvent, setUpComingEvent] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { makeRequest } = useMakeRequest()

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData(url = null) {
    makeRequest({
      route: HOME_FETCH,
      setLoader: setIsLoading,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setUpComingEvent(response.data.upcoming_event)
    }
  }

  function onError(error) {
    notifyErrors(error)
  }

  return (
    <div className="h--p">
      <Hiro />
      <Vision />

      <Partnership />
      <JackAndElizabeth />
      {upComingEvent ? <UpCommingEvents event={upComingEvent} /> : <></>}
      <Branches />
      <NewsLetter />
    </div>
  )
}

export default Home
