/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/home/JackAndElizabeth.js
Created On : 27 April 2022 14:36:47
*/

import React from "react"

const JackAndElizabeth = () => {
  return (
    <div className="h--c--section">
      <div className="h--wrapper">
        <div className="caption-container">
          <h1>Jacques & Élisabeth Ambila</h1>
          <p>
            En un mot ce qu’on peut dire sur ce couple, c’est une équipe !
            Chacun a son rôle et joue son rôle pour la restauration des
            familles.
          </p>
          <p>
            Jacques Israël Ambila est le Visionnaire et le représentant légal du
            ministère et Élisabeth Diane Ambila est la Coordination du
            Ministère.
          </p>
          <p>
            Mariés en 2007, ils ont des enfants et vivent en République
            démocratique du Congo. Ils dirigent ensemble le ministère avec une
            équipe de collaborateurs. Ils sont aussi présents dans la vie
            active.
          </p>
        </div>
        <div className="img-container">
          <img src="/couple-2.jpg" alt="Vision" />
        </div>
      </div>
    </div>
  )
}

export default JackAndElizabeth
