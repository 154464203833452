/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/routes/api.js
Created On : 01 June 2022 11:59:14
*/

export const host =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:8001"
    : "https://api.worshippersfamily.com"
export const base_url = host + "/api"

//
export const HOME_FETCH = base_url + "/home"

//
export const GET_DAILY_MESSAGE = base_url + "/daily-message"
//
export const GET_PROGRAM = base_url + "/program"
//
export const GET_ALBUMS = base_url + "/albums"

//store message
export const SAVE_MESSAGE = base_url + "/store-message"

//store message
export const SAVE_NEWS_LETTER = base_url + "/subscribe-newsletter"
