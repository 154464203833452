/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/services/httpClient.js
Created On : 12 May 2022 16:49:57
*/

import axios from "axios"

export function useMakeRequest() {
  function makeRequest({
    method = "get",
    route,
    data = [],
    setLoader,
    onSuccess,
    onError,
    successCallback, //for intermediate helper with preset action before call the on success
    errorCallback, //for intermediate helper with preset action before call the on success
  }) {
    try {
      // loading
      manageLoader(setLoader, true)
      axios[method](route, data)
        .then((response) => {
          /**  request success */
          console.log(response)
          //set response
          if (onSuccess) {
            onSuccess(response, successCallback)
          }
          //loader
          manageLoader(setLoader, false)
        })
        .catch((error) => {
          //
          if (onError) {
            onError(error, errorCallback)
          }

          //loader
          manageLoader(setLoader, false)
        })
    } catch (error) {
      /**  request failed */
      console.log(error)
    }
  }

  function makeAction({ method, route, onSuccess = null, onError = null }) {
    makeRequest({
      method: method,
      route: route,
      onSuccess: onActionSuccess,
      onError: onActionError,
      successCallback: onSuccess,
      errorCallback: onError,
    })
  }

  function onActionSuccess(response, callback) {
    if (callback) {
      callback()
    }
  }
  function onActionError(error, callback) {
    if (callback) {
      callback()
    }
  }

  return { makeRequest, makeAction }
}

/**
 *
 *
 * @param {*} manager
 * @param {*} value
 */
function manageLoader(manager, value) {
  if (manager) {
    manager(value)
  }
}
