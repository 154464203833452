/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/routes/router.js
Created On : 19 April 2022 08:03:09
*/
import React from "react"
import { Routes, Route } from "react-router-dom"
import { ABOUT, BRANCHES, CONTACTS, GIVE, HOME, MEDIAS, PROGRAM } from "."

import ContactsPage from "../pages/Contacts"
import AboutPage from "../pages/About"
import HomePage from "../pages/Home"
import Error404 from "../pages/404"
import GivePage from "../pages/Give"
import Branches from "../pages/Branches"
import Program from "../pages/Program"
import Gallery from "../pages/Gallery"

const Router = () => {
  return (
    <Routes>
      <Route path={HOME} element={<HomePage />} />
      <Route path={CONTACTS} element={<ContactsPage />} />
      <Route path={ABOUT} element={<AboutPage />} />
      <Route path={PROGRAM} element={<Program />} />
      <Route path={MEDIAS} element={<Gallery />} />
      <Route path={GIVE} element={<GivePage />} />
      <Route path={BRANCHES} element={<Branches />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  )
}

export default Router
