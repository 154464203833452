/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/Contacts.js
Created On : 19 April 2022 08:01:42
*/

import React, { useState } from "react"
import { Col, Form, InputGroup, Row } from "react-bootstrap"
import { Button, Input } from "../components/utilities"
import { useMakeRequest } from "../services/httpClient"
import { notify } from "../services/Notification"
import { SAVE_MESSAGE } from "../routes/api"
import { notifyErrors } from "../services/Validation"

const Contacts = () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [errors, setErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { makeRequest } = useMakeRequest()

  function onSubmit(e) {
    e.preventDefault()
    makeRequest({
      method: "post",
      route: SAVE_MESSAGE,
      data: {
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        email: email,
        message: message,
      },
      setLoader: setIsLoading,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 201) {
      notify({ message: "Message envoyé avec succès" })
      resetData()
    }
  }

  function resetData() {
    setErrors([])
    setFirstName("")
    setLastName("")
    setPhone("")
    setEmail("")
    setMessage("")
  }

  function onError(error) {
    notifyErrors(error, setErrors)
  }

  return (
    <div className="contact-page">
      <div className="m--intro">
        <div>
          <h1>Nous contacter</h1>{" "}
          <h4>
            Nous aimerions avoir de vos nouvelles! <br /> Remplissez le
            formulaire ci-dessous ou n'hésitez pas à nous appeler si vous avez
            besoin de quoi que ce soit.
          </h4>
        </div>
      </div>
      <div className="c-wrapper">
        <Row>
          <Col md={8}>
            <Form method="post" onSubmit={onSubmit}>
              <Row>
                <Col md={6}>
                  <Input
                    type="text"
                    name="first-name"
                    required={true}
                    label="Prenom"
                    value={firstName}
                    onChange={setFirstName}
                    error={errors.first_name}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    label="Nom de famille"
                    value={lastName}
                    onChange={setLastName}
                    name="last-name"
                    required={true}
                    error={errors.last_name}
                  />
                </Col>
              </Row>
              <Input
                label="Numero de téléphone"
                name="phone"
                type="phone"
                required={true}
                value={phone}
                onChange={setPhone}
                error={errors.phone}
                leftPrepend={
                  <InputGroup.Text id="basic-addon1">
                    <i className="fa-solid fa-phone"></i>
                  </InputGroup.Text>
                }
              />
              <Input
                label="Adresse couriel"
                name="email"
                type="email"
                value={email}
                onChange={setEmail}
                error={errors.email}
                leftPrepend={
                  <InputGroup.Text id="basic-addon1">
                    <i className="fa-solid fa-envelope"></i>{" "}
                  </InputGroup.Text>
                }
              />
              <Input
                as="textarea"
                value={message}
                onChange={setMessage}
                error={errors.message}
                label="Message"
                name="message"
                required={true}
                min={25}
              />
              <Button
                disabled={isLoading}
                isLoading={isLoading}
                type="submit"
                className="btn-secondary  my-3"
              >
                Envoyer
              </Button>
            </Form>
          </Col>
          <Col md={4} className="infos-c">
            <h4 className="subtitle">Tél:</h4>
            <ul>
              <li>
                <a href="tel:+243 910 327 364">+243 910 327 364</a>
              </li>
              <li>
                <a href="tel: +243 81444 7829"> +243 814 447 829</a>
              </li>
            </ul>
            <h4 className="subtitle">Email:</h4>
            <ul>
              <li>
                <a href="mailto:contacts@worshippersfamily.com">
                  contacts@worshippersfamily.com
                </a>
              </li>
            </ul>
            <h4 className="subtitle">Adresse:</h4>
            <p>
              1, avenue Équateur,
              <br /> Quartier Salongo, <br /> Commune Kintambo, <br />
              Kinshasa - RDC
            </p>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Contacts
