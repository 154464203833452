/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/services/Validation.js
Created On : 12 May 2022 17:07:07
*/
import { notify } from "./Notification"

export function notifyErrors(errors, setErrors = null) {
  errors = errors.response
  if (errors && errors.status === 422) {
    if (setErrors) {
      setErrors(errors.data.errors)
    }
  } else {
    notifySingleError(errors)
  }
}

export function notifySingleError(error) {
  let message = error.response.data.message
    ? error.response.data.message
    : "Une erreur s'est produite"
  notify({
    type: "error",
    message: message + ` [status = ${error.response.status}]`,
  })
}
