/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/404.js
Created On : 26 April 2022 15:37:15
*/

import React from "react"
import { Link } from "react-router-dom"
import { HOME } from "../routes"

const Error404 = () => {
  return (
    <div className="error-404-p">
      <div className="p--wrapper">
        <img src="/error_404.svg" alt="Erreur 404" />
        <h1 className="">Cette page n'est plus disponible</h1>
        <h4>
          ... Peut-être que la page que vous recherchez est introuvable ou n'a
          jamais existé
        </h4>
        <Link to={HOME}>Retourner à l'accueil</Link>
      </div>
    </div>
  )
}

export default Error404
