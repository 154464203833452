/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/Gallery.js
Created On : 23 May 2022 14:29:15
*/

import React, { useEffect, useState } from "react"
import { Carousel, Col, Modal, Row } from "react-bootstrap"
import { Button } from "../components/utilities"
import { GET_ALBUMS } from "../routes/api"
import { useMakeRequest } from "../services/httpClient"

const Gallery = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [albums, setAlbums] = useState([])
  const [selectedAlbum, setSelectedAlbum] = useState(null)
  const { makeRequest } = useMakeRequest()

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData(url = null) {
    makeRequest({
      route: GET_ALBUMS,
      setLoader: setIsLoading,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setAlbums(response.data.albums)
    }
  }

  function onError(error) {}

  const Caroussel = () => {
    return (
      <Modal
        animation={true}
        size="lg"
        show={selectedAlbum}
        onHide={() => setSelectedAlbum(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedAlbum.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Carousel fade>
              {selectedAlbum?.photos.map((ite, k) => (
                <Carousel.Item>
                  <img className="d-block w-100" src={ite.normal} alt="" />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <div className="gallery-p">
      <div className="wrapper">
        <h1 className="title">Galerie</h1>

        <div className="mb-3">
          <Row>
            {albums.map((al) => (
              <Col md={3} className="mb-3" key={al.id}>
                <img
                  src={al.image}
                  alt=""
                  className="img-thumbnail mb-2"
                  width="100%"
                />
                <Button
                  onClick={() => setSelectedAlbum(al)}
                  variant="outline-primary"
                  className="w-100"
                >
                  Voir
                </Button>
              </Col>
            ))}
          </Row>
        </div>
        <div className="image wrapper">{selectedAlbum && <Caroussel />}</div>
      </div>
    </div>
  )
}

export default Gallery
