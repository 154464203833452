/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/Header.js
Created On : 18 April 2022 11:17:54
*/

import React, { useState } from "react"
import { Button, Offcanvas } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import {
  ABOUT,
  BRANCHES,
  CONTACTS,
  EPISODES,
  GIVE,
  HOME,
  MEDIAS,
  PROGRAM,
} from "../routes"

const Header = () => {
  const [showMenus, setShowMenus] = useState(false)
  const navigate = useNavigate()

  function redirectTo(e, url) {
    e.preventDefault()
    setShowMenus(false)
    navigate({ pathname: url })
  }

  const MenuList = () => {
    return (
      <>
        <Link onClick={(e) => redirectTo(e, HOME)} to={ABOUT}>
          Accueil
        </Link>
        <Link onClick={(e) => redirectTo(e, ABOUT)} to={ABOUT}>
          A propos
        </Link>
        <Link onClick={(e) => redirectTo(e, BRANCHES)} to={BRANCHES}>
          Nos branches
        </Link>
        <Link onClick={(e) => redirectTo(e, PROGRAM)} to={PROGRAM}>
          Programme
        </Link>
        <Link onClick={(e) => redirectTo(e, MEDIAS)} to={MEDIAS}>
          Galerie
        </Link>
        <Link onClick={(e) => redirectTo(e, CONTACTS)} to={CONTACTS}>
          Contact
        </Link>
        <Link onClick={(e) => redirectTo(e, GIVE)} to={GIVE} className="give">
          Faire un don
        </Link>
      </>
    )
  }

  return (
    <div className="master-header">
      <div className="wrapper">
        <div className="logo-c">
          <Link to={HOME}>
            <img src="/logo-inline.png" alt="" />
          </Link>
        </div>
        <div className="menus-c">
          <MenuList />
        </div>
        <div className="mobile--only ">
          <Link
            to="/"
            className="sm-menus--toggler"
            onClick={(e) => {
              e.preventDefault()
              setShowMenus(true)
            }}
          >
            <i className="fa-solid fa-bars"></i>
          </Link>
          <Offcanvas
            show={showMenus}
            onHide={() => setShowMenus(false)}
            backdrop={true}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Menus</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="mb-menus-c">
                <MenuList />
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>
    </div>
  )
}

export default Header
