/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/home/Partnership.js
Created On : 26 April 2022 18:02:27
*/

import React from "react"
import { Link } from "react-router-dom"
import { CONTACTS } from "../../routes"

const Partnership = () => {
  return (
    <div className="h--partnership">
      <div className="c--content">
        <h2>Devenez partenaire</h2>
        <h5>
          Nous nous engageons à travailler avec un vaste éventail de partenaires
          et à étendre notre réseau communautaire,
        </h5>
        <Link to={CONTACTS}>Nous contacter</Link>
      </div>
    </div>
  )
}

export default Partnership
