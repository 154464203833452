/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/home/Hiro.js
Created On : 26 April 2022 16:19:51
*/
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { ABOUT } from "../../routes"
import { GET_DAILY_MESSAGE } from "../../routes/api"
import { useMakeRequest } from "../../services/httpClient"

const Hiro = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [daily, setDaily] = useState(null)
  const { makeRequest } = useMakeRequest()

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData(url = null) {
    makeRequest({
      route: GET_DAILY_MESSAGE,
      setLoader: setIsLoading,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setDaily(response.data.daily)
    }
  }

  function onError(error) {}

  return (
    <div className="h--top">
      <div className="h--hiro">
        <div className="hi--wrapper">
          <h3>La famille des adorateurs</h3>
          <h1>
            « La Restauration d’une famille des adorateurs pour notre Dieu »
          </h1>
          <Link to={ABOUT}>Nous connaître</Link>
        </div>
      </div>
      <div className="themes">
        <div className="f-side">
          <div>
            <h2>
              Tous les mercredis à <b> 17h : </b>
            </h2>
            <h4>
              Facebook - YouTube Live :<b> Jacques Elisabeth Ambila</b>
            </h4>
          </div>
        </div>
        <div className="s-side">
          {daily && (
            <div>
              <h2>{daily.title}</h2>
              <h4>{daily.content}</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Hiro
