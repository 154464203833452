/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/layouts/master.js
Created On : 18 April 2022 10:38:53
*/

import React from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
const master = ({ children }) => {
  return (
    <div className="master-layout">
      <div className="wrapper">
        <Header />
        <div className="master-app-content">{children}</div>
        <Footer />
      </div>
    </div>
  )
}

export default master
