/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/home/Branches.js
Created On : 28 April 2022 15:24:55
*/

import React from "react"
import { Link } from "react-router-dom"
import { BRANCHES, EPISODES } from "../../routes"

const Branches = () => {
  const ItemDisplayer = ({ item }) => {
    return (
      <div className="item-displayer">
        <h4>{item.title}</h4>
        <div className="subtitle">{item.description}</div>
        <Link to={BRANCHES}>En savoir plus</Link>
      </div>
    )
  }

  return (
    <div className="h--branches">
      <div className="h--wrapper">
        <h1 className="title">Nos branches</h1>
        <ul>
          {items.map((item, key) => (
            <li key={key}>
              <ItemDisplayer item={item} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Branches

const items = [
  {
    title: "Soirée adoration en famille",
    description: "La branche qui organise les soirées d’adoration en famille.",
  },
  {
    title: "Atelier Mahombi Pour les familles",
    description: "Une journée de prière et d’intercession pour les familles.",
  },
  {
    title: "J’aime ma Famille",
    description:
      "Un programme de diffusion des séries d'enseignements sur la famille sur YouTube et les chaînes de Télé.",
  },
  {
    title: "Actions Dorcas Davida",
    description:
      "La branche compassion du ministère où sa vision pour les familles se réalise à travers l’assistance aux démunis",
  },
  {
    title: "Sans Tabou",
    description: "« Réunion d’une Mère avec ses filles, sans tabou! »",
  },
  {
    title: "Brunch Women (Déjeuner des femmes) ",
    description:
      "Une matinée évangélique pour le salut des femmes et d’échanges d’expériences",
  },
  {
    title: "A Nous Deux pour La Vie",
    description: "Une branche spécialement axée sur les couples.",
  },
  {
    title: "Hommes Excellents ",
    description:
      "L’excellence est une valeur. Les valeurs d’excellence se transmettent d’une personne à une autre.",
  },
]
