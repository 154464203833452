/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/Program.js
Created On : 23 May 2022 13:29:01
*/

import React, { useState, useEffect } from "react"
import { Col, Row, Table } from "react-bootstrap"
import { Link } from "react-router-dom"
import { CONTACTS } from "../routes"
import { GET_PROGRAM } from "../routes/api"
import { useMakeRequest } from "../services/httpClient"

const Program = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [program, setPrgram] = useState([])
  const { makeRequest } = useMakeRequest()

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData(url = null) {
    makeRequest({
      route: GET_PROGRAM,
      setLoader: setIsLoading,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setPrgram(response.data.events)
    }
  }

  function onError(error) {}
  return (
    <div className="program-page">
      <div className="p--wrapper">
        <h1 className="title">Nos Programmes</h1>
        <div className="items">
          <Row>
            {program.map((item, key) => (
              <Col md={4} key={key}>
                <ProgramDisplayer item={item} />
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Program

export const ProgramDisplayer = ({ item }) => {
  return (
    <div className="program-displayer">
      <img src={item.image} alt={item.name} />
      <div className="table-responsive">
        <Table>
          <tbody>
            <tr>
              <td colSpan={2}>
                <h4 className="p--title">{item.name}</h4>
              </td>
            </tr>
            <tr>
              <th scope="line">Description</th>
              <td>
                <h6 className="p--subtitle">{item.caption}</h6>
              </td>
            </tr>
            <tr>
              <th scope="line">Date</th>
              <td>
                <h6 className="p--subtitle">
                  Du {item.starts_at} au {item.ends_at}
                </h6>
              </td>
            </tr>
          </tbody>
        </Table>
        {item.can_reserve ? <Link to={CONTACTS}>Reserver</Link> : <></>}
      </div>
    </div>
  )
}
