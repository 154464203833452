/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/Give.js
Created On : 28 April 2022 14:45:46
*/

import React from "react"
import { Card, Col, Row, Form, InputGroup } from "react-bootstrap"
import { Link } from "react-router-dom"
import { Button, Input } from "../components/utilities"
import { HOME } from "../routes"

const Give = () => {
  const GiveOption = () => {
    return (
      <div className="give--p">
        <div className="g--wrapper">
          <h1 className="title">Faire un don</h1>
          <div className="c--wrapper">
            <Card>
              <Card.Body>
                <Form>
                  <Input
                    label="Montant"
                    rightPrepend={
                      <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                    }
                  />
                  <Row>
                    <Col md={6}>
                      <Input label="Nom" />
                    </Col>
                    <Col md={6}>
                      <Input label="Prénom" />
                    </Col>
                  </Row>
                  <Input label="E-mail" />
                  <Button className="w-100 text-white">Donner </Button>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="give--waiting">
      <div>
        <h1>Bientôt disponible</h1>
        <Link to={HOME}>Page d'acceuil</Link>
      </div>
    </div>
  )
}

export default Give
