/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/NewsLetter.js
Created On : 07 June 2022 16:14:26
*/

import React, { useState } from "react"
import { Form } from "react-bootstrap"
import { SAVE_NEWS_LETTER } from "../routes/api"
import { useMakeRequest } from "../services/httpClient"
import { notify } from "../services/Notification"
import { notifyErrors } from "../services/Validation"
import { Button, Input } from "./utilities"

const NewsLetter = () => {
  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState(false)
  const { makeRequest } = useMakeRequest()

  function onSubmit(e) {
    e.preventDefault()
    makeRequest({
      method: "post",
      route: SAVE_NEWS_LETTER,
      data: { email: email },
      setLoader: setIsLoading,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 201) {
      notify({ message: "Soucsription réussi" })
      setEmail("")
      setErrors([])
    }
  }

  function onError(error) {
    notifyErrors(error, setErrors)
  }

  return (
    <div className="newsletter-c">
      <div className="nc--wrapper">
        <h1 className="nc--title">Abonnez-vous à notre NewsLetter </h1>
        <h3 className="nc--subtitle">
          Pour recevoir nos différents progrmammes déposez votre e-mail
          ci-dessous .
        </h3>
        <Form method="post" onSubmit={onSubmit}>
          <Input
            placeholder="E-mail"
            name="newsletter"
            type="email"
            value={email}
            onChange={setEmail}
            error={errors.email}
            rightPrepend={
              <Button type="submit" variant="primary">
                S'abonner
              </Button>
            }
          />
        </Form>
      </div>
    </div>
  )
}

export default NewsLetter
