/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/routes/index.js
Created On : 19 April 2022 08:02:23
*/

export const HOME = "/"
export const CONTACTS = "/contacts"
export const BRANCHES = "/nos-branches"
export const EPISODES = "/emissions"
export const PROGRAM = "/programmes"
export const ABOUT = "/a-propos"
export const GIVE = "/dons"
export const MEDIAS = "/medias"
