/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/Footer.js
Created On : 18 April 2022 11:18:07
*/

import React from "react"
import { Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import { ABOUT } from "../routes"

const Footer = () => {
  return (
    <div className="m-footer">
      <div className="wrapper">
        <Row>
          <Col md={3} className="mb-3">
            <h1 className="s--title">Nous connaître</h1>
            <ul>
              <li>
                <Link to={ABOUT}>A propos de nous</Link>
              </li>
              <li>
                <Link to="/">Devenir partenaire</Link>
              </li>
            </ul>
          </Col>
          <Col md={3} className="mb-3">
            <h1 className="s--title">Nos émissions</h1>
            <ul>
              <li>
                <Link to="/">Soirée adoration</Link>
              </li>
              <li>
                <Link to="/">Atélier Mahombi</Link>
              </li>
              <li>
                <Link to="/">Actions Dorcas Davida</Link>
              </li>
              <li>
                <Link to="/">Sans Tabou</Link>
              </li>
              <li>
                <Link to="/">Brunch women</Link>
              </li>
              <li>
                <Link to="/">A nous deux pour la vie</Link>
              </li>
              <li>
                <Link to="/">Hommes excellents</Link>
              </li>
            </ul>
          </Col>
          <Col md={3} className="mb-3">
            <h1 className="s--title">Contacts</h1>
            <ul>
              <li>
                <h4 className="subtitle">Téléphone</h4>
                <ul>
                  <li>
                    <a href="tel:+243 910 327 364">+243 910 327 364</a>
                  </li>
                  <li>
                    <a href="tel: +243 81444 7829"> +243 814 447 829</a>
                  </li>
                </ul>
              </li>
              <li>
                <h4 className="subtitle">Mail</h4>
                <ul>
                  <li>
                    <a href="mailto:contacts@worshippersfamily.com">
                      contacts@worshippersfamily.com
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <h4 className="subtitle">Adresse</h4>
                <p>
                  1, avenue Équateur,
                  <br /> Quartier Salongo, <br /> Commune Kintambo, <br />
                  Kinshasa - RDC
                </p>
              </li>
            </ul>
          </Col>
          <Col md={3} className="mb-3">
            <h1 className="s--title">Nous suivre</h1>
            <ul>
              <li>
                <a
                  className=""
                  href="https://www.youtube.com/channel/UCIUWHG8BBOlp2zxwB2rJr4w"
                  target="_blank"
                  rel="noreferrer"
                >
                  Youtube
                </a>
              </li>
              <li>
                <a
                  className=""
                  href="https://web.facebook.com/JacquesIsraelAmbila"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  className=""
                  href="https://www.instagram.com/jacques_elisabeth_ambila/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  className=""
                  href="https://twitter.com/JacquesAmbila"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Footer
