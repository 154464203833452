import React from "react"
import { Form, InputGroup } from "react-bootstrap"

const Input = React.memo(
  ({
    name,
    label,
    type = "text",
    placeholder = "",
    value,
    onChange,
    disabled = false,
    error = null,
    isSelect = false,
    options = [],
    text,
    as,
    leftPrepend,
    rightPrepend,
    accept,
    multiple = false,
    required,
    plaintext,
    readOnly,
    selected,
  }) => {
    const setOnChange = (e) => {
      if (type !== "file") {
        onChange(e.target.value)
        return
      }
      // let reader = new FileReader()
      // reader.onload = () => {
      //   let url = reader.result
      //   onChange(url)
      // }
      // reader.readAsDataURL(e.target.files[0])

      let files = Array.from(e.target.files)
      let images = []
      files.forEach((file) => {
        let reader = new FileReader()
        reader.onload = () => {
          let url = reader.result
          images.push(url)
        }
        reader.readAsDataURL(file)
      })
      onChange(images)
    }

    return (
      <Form.Group className="form-group">
        {label && <Form.Label>{label}</Form.Label>}
        {!isSelect ? (
          <InputGroup>
            {leftPrepend && leftPrepend}
            <Form.Control
              as={as}
              name={name}
              value={value}
              onChange={(e) => setOnChange(e)}
              type={type}
              placeholder={placeholder}
              disabled={disabled}
              isInvalid={error}
              accept={accept}
              multiple={multiple}
              plaintext={plaintext}
              readOnly={readOnly}
              required={required}
            />
            {rightPrepend && rightPrepend}
          </InputGroup>
        ) : (
          <Form.Select
            name={name}
            value={value ? value : ""}
            onChange={(e) => setOnChange(e)}
            placeholder={placeholder}
            disabled={disabled}
            isInvalid={error}
          >
            {!selected && <option value="">Aucun</option>}
            {options.map((option, k) => (
              <option
                key={k}
                value={option.value}
                // selected={selected === option.value}
              >
                {option.caption}
              </option>
            ))}
          </Form.Select>
        )}

        {text && <Form.Text muted>{text}</Form.Text>}
        {error && (
          <div>
            <Form.Text className="text-danger">{error}</Form.Text>
          </div>
        )}
      </Form.Group>
    )
  }
)

export default Input
